import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'
import { Provider } from 'react-redux'
import MomentUtils from '@date-io/moment'

import routes, { renderRoutes } from 'src/routes'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import validators from './common/validators'
import { configureStore } from './store'

const browserHistory = createBrowserHistory()
const store = configureStore()

validate.validators = {
  ...validate.validators,
  ...validators
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider autoHideDuration={1000} maxSnack={3}>
            <Router history={browserHistory}>{renderRoutes(routes)}</Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
