/**
 * Utility to make all API calls
 */
import axios from 'axios'

const axiosInstance = axios.create({})

const accessToken = localStorage.getItem('accessToken')

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken || ''}` 

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error.response.status === 401){
      localStorage.removeItem('accessToken')
      window.location.href = '/sign-in'
    }
    return Promise.reject(
      (error.response && error.response.data) ||
      'Something went wrong (axios.js)'
    )
  }
)

export default axiosInstance
