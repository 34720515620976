import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Drawer } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import GroupIcon from '@material-ui/icons/Group'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import { SidebarNav } from './components'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}))

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Notifications',
      href: '/notifications/1',
      icon: <NotificationsNoneIcon />
    },
    {
      title: 'Colleges',
      href: '/college-list',
      icon: <AccountBalanceIcon />
    },
    {
      title: 'Ebooks',
      href: '/ebooks',
      icon: <MenuBookIcon />
    },
    {
      title: 'Ads',
      href: '/ads/1',
      icon: <PictureInPictureAltIcon />
    },
    {
      title: 'Ad Clients',
      href: '/ad-clients',
      icon: <GroupIcon />
    }
  ]

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

export default Sidebar
