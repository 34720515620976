import React, { Fragment, lazy, Suspense } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import MainLayout from 'src/layouts/Main'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            exact={route.exact}
            key={i}
            path={route.path}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/NotFound'))
  },
  {
    exact: true,
    path: '/sign-in',
    component: lazy(() => import('src/views/LoginView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/college-list',
        component: lazy(() => import('src/views/CollegeList'))
      },
      {
        exact: true,
        path: '/college-add',
        component: lazy(() => import('src/views/CollegeAdd'))
      },
      {
        exact: true,
        path: '/college-profile/:collegeId?',
        component: lazy(() => import('src/views/CollegeProfile'))
      },
      {
        exact: true,
        path: '/cl/:siteKey/:id/edit',
        component: lazy(() => import('src/views/CollegeEdit'))
      },
      {
        exact: true,
        path: '/cl/:siteKey/:page',
        component: lazy(() => import('src/views/SiteCollegeList'))
      },
      {
        exact: true,
        path: '/ad-clients',
        component: lazy(() => import('src/views/AdClient/AdClientList'))
      },
      {
        exact: true,
        path: '/ad-clients/create',
        component: lazy(() => import('src/views/AdClient/AdClientCreate'))
      },
      {
        exact: true,
        path: '/ads/create',
        component: lazy(() => import('src/views/Ad/AdCreate'))
      },
      {
        exact: true,
        path: '/ads/:page',
        component: lazy(() => import('src/views/Ad/AdList'))
      },
      {
        exact: true,
        path: '/ebooks',
        component: lazy(() => import('src/views/Ebook/EbookList'))
      },
      {
        exact: true,
        path: '/ebooks/create',
        component: lazy(() => import('src/views/Ebook/EbookCreate'))
      },
      {
        exact: true,
        path: '/ebooks/:bookId/update',
        component: lazy(() => import('src/views/Ebook/EbookUpdate'))
      },
      {
        exact: true,
        path: '/notifications/create',
        component: lazy(() => import('src/views/Notification/NotificationCreate'))
      },
      {
        exact: true,
        path: '/notifications/:notificationId/update',
        component: lazy(() => import('src/views/Notification/NotificationUpdate'))
      },
      {
        exact: true,
        path: '/notifications/:page',
        component: lazy(() => import('src/views/Notification/NotificationList'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/Account'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/LoginView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]

export default routes
