import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import authService from 'src/services/authService'

function AuthGuard({ children }) {
  if (!authService.isAuthenticated()) {
    return <Redirect to="/sign-in" />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
