import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core'
import { logout } from '../../../../actions/accountActions'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}))

function Account() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const ref = useRef(null)
  const dispatch = useDispatch()
  const account = useSelector((state) => state.account)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await dispatch(logout())
      history.push('/')
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      })
    }
  }

  return (
    <>
      <Box alignItems="center" component={ButtonBase} display="flex" onClick={handleOpen} ref={ref}>
        <Avatar alt="User" className={classes.avatar} src={null} />
        <Hidden smDown>
          <Typography color="inherit" variant="h6">
            {(account.user && account.user.first_name) || 'User'}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={isOpen}>
        <MenuItem component={RouterLink} to="/app/account">
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default Account
