/**
 * List of apis used in the application
 */
const localServer = false
export const imageUrl = 'https://images.i3c.tech'
let baseUrl = localServer ? 'http://localhost:8000' : 'https://api.i3c.tech'

// Always use api url for build
if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://api.i3c.tech'
}

// Common APIs
export const loginAPI = () => `${baseUrl}/users/admin/login`
export const getInitialDataAPI = () => `${baseUrl}/initial-data`
export const collegeProfileListAPI = () => `${baseUrl}/colleges`
export const collegeProfileFindAPI = () => `${baseUrl}/colleges/find`
export const collegeProfileAPI = (collegeId) => `${baseUrl}/colleges/${collegeId}`
export const collegeProfileSeoAPI = (collegeId) => `${baseUrl}/colleges/${collegeId}/seo`
export const collegeProfileAddressAPI = (collegeId) => `${baseUrl}/colleges/${collegeId}/address`
export const collegeGalleryAPI = (collegeId) => `${baseUrl}/colleges/${collegeId}/images/gallery`
export const collegeGalleryImageAPI = (collegeId, imageId) => `${baseUrl}/colleges/${collegeId}/images/gallery/${imageId}`
export const collegeProfileImagesAPI = (collegeId, type) => `${baseUrl}/colleges/${collegeId}/images/${type}`

// Website's APIs
export const collegeAPI = (siteId, collegeId) => `${baseUrl}/${siteId}/colleges/${collegeId}`
export const collegeDetailsAPI = (siteId, collegeId) => `${baseUrl}/${siteId}/colleges/${collegeId}/details`
export const collegeListAPI = (siteId, page = 0) => `${baseUrl}/${siteId}/colleges?page=${page}`
export const collegeSearchAPI = (siteId, query) => `${baseUrl}/${siteId}/colleges/search?q=${query}`

// Advertisement APIs
export const getAdsList = (page) => `${baseUrl}/ads/list?page=${page}`
export const getAdClientOptions = () => `${baseUrl}/ads/clients/options`
export const getAdClientsAPI = () => `${baseUrl}/ads/clients`

// Notifications
export const notificationListAPI = (page) => `${baseUrl}/shared/notifications?page=${page}`
export const notificationDetailsAPI = (notificationId) => `${baseUrl}/shared/notifications/details/${notificationId}`

// Ebooks
export const ebooksListAPI = () => `${baseUrl}/shared/ebooks`
export const ebookDetailsAPI = (bookId) => `${baseUrl}/shared/ebooks/details/${bookId}`
