import jwtDecode from 'jwt-decode'
import axios from 'src/utils/axios'
import { loginAPI } from 'src/config/apis'

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null)

          if (onLogout) {
            onLogout()
          }
        }

        return Promise.reject(error)
      }
    )
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken()

    if (!accessToken) {
      return
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken)
    } else {
      this.setSession(null)
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post(loginAPI(), { mobile: email, password })
        .then((response) => {
          if (response.data) {
            const token = response.data.data
            const user = jwtDecode(token)
            this.setSession(token)
            resolve(user)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/account/me')
        .then((response) => {
          if (response.data.user) {
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  logout = () => {
    this.setSession(null)
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
      localStorage.removeItem('accessToken')
      delete axios.defaults.headers.common.Authorization
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken')

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false
    }

    const decoded = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000

    return decoded.exp > currentTime
  }

  isAuthenticated = () => {
    const accessToken = this.getAccessToken()
    if (accessToken) {
      this.setSession(accessToken)
      return true
    }
    return false
  }

  getUserObject = () => {
    if (!this.isAuthenticated()) {
      return null
    }
    return jwtDecode(this.getAccessToken())
  }
}

const authService = new AuthService()

export default authService
